import React from "react"
import Layout from "../../components/layout"
import BackButton from "../../components/BackBtn"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export default function portfolio({ data }) {
  return (
    <Layout>
      <Seo
        title="Portfolio"
        description="Bekijk hier mijn complete portfolio."
      />
      <main className="main-content main-portfolio">
        <div className="title-container">
          <h1 className="main-title">Portfolio</h1>
          <BackButton path="../" text="Terug" />
        </div>
        <p className="main-description">
          Mijn portfolio in continu in ontwikkeling. Een voorlopige indeling is
          die in documentaires (waar mijn hart ligt), portretten (een boeiende
          uitdaging), landschappen en architectuur (de pracht van natuur en
          mensen) en stillevens (heerlijk prutsen binnen).
        </p>
        <div className="card-container">
          <Link to="../Portfolio/landschappen" className="card__link">
            <div className="card">
              <StaticImage
                src="../../images/thumbs/landschappen.jpg"
                alt="landschappen"
                className="card-img"
              />
              <h5 className="card__title">Landschappen</h5>
            </div>
          </Link>
          <Link to="../Portfolio/portretten" className="card__link">
            <div className="card">
              <StaticImage
                src="../../images/thumbs/zelfportret-2023.jpg"
                alt="portrets"
                className="card-img"
              />
              <h5 className="card__title">Portretten</h5>
            </div>
          </Link>

          <Link to="../Portfolio/architectuur" className="card__link">
            <div className="card">
              <StaticImage
                src="../../images/thumbs/architectuur.jpg"
                alt="architecture"
                className="card-img"
              />
              <h5 className="card__title">Architectuur</h5>
            </div>
          </Link>

          <Link to="../Portfolio/stillevens" className="card__link">
            <div className="card">
              <StaticImage
                src="../../images/thumbs/stillevens.jpg"
                alt="still life"
                className="card-img"
              />
              <h5 className="card__title">Stillevens</h5>
            </div>
          </Link>
        </div>
      </main>
    </Layout>
  )
}
